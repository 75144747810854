import { Switch } from "antd";

export default function SwitchComponent({ title, checked, onChange }) {
    return (
        <div style={{ marginBottom: 10 }}>
            <Switch size="small" checked={checked} onChange={onChange} />
            {title && <span style={{ marginLeft: 5 }}>{title}</span>}
        </div>
    );
}
