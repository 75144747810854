import axios from "axios";

const request = (
  method,
  url,
  body,
  callback,
  errorCallback,
  notTimeout,
  tokenByLocal = false
) => {
  let myAxios = axios.create();

  if (!notTimeout) {
    myAxios.defaults.timeout = 30000;
  }

  // myAxios.defaults.headers.common["Authorization"] =
  //   localStorage.getItem("TOKEN");

  myAxios.defaults.headers.common["Authorization"] = tokenByLocal
    ? `Bearer ${localStorage.getItem("TOKEN")}`
    : `Bearer ${sessionStorage.getItem("TOKEN")}`;
  myAxios[method](url, method === "delete" ? { data: body } : body)
    .then((response) => {
      if (response.data.error) {
        if (response.data.message === "Forbiden") {
          window.location.href = "#";
        }
        if (errorCallback) {
          errorCallback(response.data.message);
        }
      } else {
        if (response.data && response.data.message) {
          callback(response.data.message);
        }
      }
    })
    .catch((error) => {
      if (errorCallback) {
        if (error.response && error.response.data) {
          errorCallback(error.response.data);
        }
      }
    });
};

export default request;
