import { Input } from "antd";

import mod from "./style/inputComponentStyle.module.scss";

export default function InputComponent(props) {
    switch (props.type) {
        case "password":
            return <Input.Password className={mod["input-component"]} {...props} />;

        case "file":
            return (
                <div className="m3u8-file-input" placeholder="test">
                    {props.name ? (
                        <span style={{ color: "white", marginLeft: 10 }}>{props.name}</span>
                    ) : (
                        <span style={{ color: "#bfbfbf", marginLeft: 10 }}>M3U8 file</span>
                    )}
                    <Input type="file" {...props} style={{ width: "100%" }} />
                </div>
            );

        default:
            return <Input className={mod["input-component"]} {...props} />;
    }
}
