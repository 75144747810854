// const HOST = process.env.REACT_APP_API_URL || "https://xplayer-api.inorain.com/";
const HOST = process.env.REACT_APP_API_URL ;
// const HOST = 'http://192.168.8.135:10034/';
// const RESELLER_HOST = process.env.REACT_APP_RESELLER_URL || "https://xplayer-reseller.inorain.com/";
const RESELLER_HOST = process.env.REACT_APP_RESELLER_URL ;
const API_HOST = `${HOST}api/`;

const VERSION = `v1.0.1`

export { API_HOST, HOST, RESELLER_HOST, VERSION };
