import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";

import IMAGES from "../../config/images";

import { ICONS } from "../../config/icons";
import "./style.scss";

export default function NotFound() {
  const webSettings = useSelector((state) => state.globalState.webSettings);
  const navigate = useNavigate();

  const pageStyle = {
    backgroundColor: webSettings && webSettings.background_color,
    backgroundImage: webSettings && `url(${webSettings.background_image})`,
    backgroundSize: "cover",
  };

  return (
    <div className="page-wrapper" style={pageStyle}>
      <div className="notfound-page">
        <div className="notfound-page_first-block">
          <img
            src={IMAGES.NOT_fOUND}
            alt=""
            srcset=""
            className="notfound-page_first-block_img"
          />
          <p className="notfound-page_first-block_txt">We can’t seem to find the page you’re looking for.</p>
          <button
          className="notfound-page_button"
            onClick={() => {
              navigate("/home");
            }}
          >
            RETURN HOME
          </button>
        </div>
        <div className="notfound-page_img"></div>
      </div>
    </div>
  );
}
