import React, { useState } from "react";

import EditPlaylist from "./EditPlaylist";
import { ICONS } from "../../config/icons";
import DeletePlaylistModal from "./DeletePlaylistModal";

import style from "./style/style.module.scss";

export default function Playlist({ playlist, getPlaylists, deviceId }) {
  const [openDelteModal, setOpenDeleteModal] = useState(false);

  const [visibleEditPlaylistModal, setVisibleEditPlaylistModal] =
    useState(false);

  return (
    <div>
      <div className={style["single-playlist"]}>
        <div className={style["single-playlist__name-url"]}>
          <div className={style["name"]}>{playlist.name}</div>
          <div className={style["url"]}>{playlist.url}</div>
        </div>

        <div className={style["edit-delete"]}>
          <div
            className={style["edit"]}
            onClick={() => setVisibleEditPlaylistModal(true)}
          >
            {ICONS.PLAYLIST_EDIT}
          </div>
          <div
            className={style["delete"]}
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            {ICONS.PLAYLIST_DELETE}
          </div>
        </div>
      </div>
      <DeletePlaylistModal
        open={openDelteModal}
        setOpen={setOpenDeleteModal}
        getPlaylists={getPlaylists}
        id={playlist.id}
      />
      <EditPlaylist
        visible={visibleEditPlaylistModal}
        onCancel={() => setVisibleEditPlaylistModal(false)}
        getPlaylists={getPlaylists}
        deviceId={deviceId}
        playlist={playlist}
      />
    </div>
  );
}
