const web_settings = {
    globals: {
        logo: "https://api.jaroiptv.com/images/image1_logo_site.png",
        logo_size: 50,
        background_color: "#165262",
        text_color: "#ffffff",
        title_color: "#ffffff",
        active_color: "#4ce146",
        hover_color: "#89e685",
        buttons_text_color: "black",
    },

    navbar: {
        background_color: "black",
        text_color: "white",
    },

    footer: {
        background_color: "black",

        payments_cards: {
            enabled: false,
            image: "",
        },

        social_links: [
            {
                id: 1,
                enabled: true,
                name: "Facebook",
                link: "https://www.facebook.com/",
                icon: "https://api.jaroiptv.com/images/value-facebook_1671782207004.png",
            },

            {
                id: 2,
                enabled: true,
                name: "Instagram",
                link: "https://www.instagram.com/",
                icon: "https://api.jaroiptv.com/images/value-instagram_1671296761432.png",
            },

            {
                id: 3,
                enabled: true,
                name: "Linkedin",
                link: "https://www.linkedin.com/",
                icon: "https://api.jaroiptv.com/images/value-linkedin_1671296771080.png",
            },

            {
                id: 4,
                enabled: true,
                name: "Telegram",
                link: "https://telegram.org/",
                icon: "https://api.jaroiptv.com/images/value-telegram_1671296780212.png",
            },

            {
                id: 5,
                enabled: true,
                name: "Twitter",
                link: "https://twitter.com/",
                icon: "https://api.jaroiptv.com/images/value-twitter_1671296787559.png",
            },

            {
                id: 6,
                enabled: true,
                name: "Youtube",
                link: "https://www.youtube.com/",
                icon: "https://api.jaroiptv.com/images/value-youtube_1671296793191.png",
            },

            {
                id: 7,
                enabled: true,
                name: "Whatsapp",
                link: "https://www.whatsapp.com/",
                icon: "https://api.jaroiptv.com/images/value-whatsapp_1671296799165.png",
            },

            {
                id: 8,
                enabled: true,
                name: "Tiktok",
                link: "https://www.tiktok.com/",
                icon: "https://api.jaroiptv.com/images/value-tiktok_1671302649108.png",
            },

            {
                id: 9,
                enabled: true,
                name: "Pinterest",
                link: "https://www.pinterest.com/",
                icon: "https://api.jaroiptv.com/images/value-pinterest_1671296810706.png",
            },
        ],
    },

    pages: {
        home: {
            header: {
                background_color: "",
                background_image: "",
            },

            section_one: {
                background_color: "",
                background_image: "",
                icon: "https://api.jaroiptv.com/images/value-section_one_1671782182059.png",
                icon_size: 400,
            },

            section_two: {
                background_color: "",
                background_image: "",
                icon: "https://api.jaroiptv.com/images/value-section_two_1670573112475.png",
                icon_size: 400,
            },

            section_three: {
                background_color: "",
                background_image: "",
                icon: "https://api.jaroiptv.com/images/value-section_three_1670573230036.png",
                icon_size: 400,
            },

            news_section: {
                background_color: "",
                background_image: "",
            },
        },

        activation: {
            background_color: "",
            background_image: "",
        },

        upload_playlist: {
            background_color: "",
            background_image: "",
        },

        register: {
            background_color: "",
            background_image: "",
        },

        news: {
            background_color: "",
            background_image: "",
        },

        current_news: {
            background_color: "",
            background_image: "",
        },

        contact_us: {
            background_color: "",
            background_image: "",

            location: {
                enabled: true,
                icon: "https://api.jaroiptv.com/images/value-location_1670576377454.png",
            },

            phone: {
                enabled: true,
                icon: "https://api.jaroiptv.com/images/value-phone_1670576734820.png",
            },

            email: {
                enabled: true,
                icon: "https://api.jaroiptv.com/images/value-email_1670576741656.png",
            },
        },

        login: {
            background_color: "",
            background_image: "",
        },

        playlist: {
            background_color: "",
            background_image: "",
        },

        chat: {
            background_color: "",
            background_image: "",
        }
    },

    translations: {
        en: {
            general: {
                all_rights_reserved: "All Rights Reserved",
                close: "Close",
                mac_input_message: "Please input your mac address",
                mac_input_valid_message: "Mac address is valid",
                mac_input_not_valid_message: "Mac address is not valid",
                mac_input_placeholder: "Mac address",
                login: "Login",
            },

            pages: {
                home: {
                    page_name: "Home",
			
//                    header_title:  "Unlimited movies, TV shows, and live TV in a Comfortable interface.",
		    header_title: "Pay once and activate your device for 4 euros only",

                    header_second_title: "Submit Watch anywhere. Cancel anytime.",
                    header_description:
                        "Ready to watch? Enter your email to create or restart your membership.",
                    header_activate_app_button: "Activate app",
                    header_upload_playlist_button: "Upload playlist",

                    section_one_title: "Launch Your X Palyer In 7 Days",
                    section_one_description:
                        "With X-Player you can use all modern functional lists of the players...",

 //            section_two_title: "Watch Live TV with comfort",
                    section_two_title: "",
//                  section_two_description:             "Our good organized UI will bring you a new level of watching live tv programs. Pick a channel, Watch, Save to favorites, and the app will start suggesting to you what you like to watch.",
//            section_two_description: "Our good organized UI will bring you a new level of watching live tv programs. Pick a channel, Watch, Save to favorites, and the app will start suggesting to you what you like to watch.",
                    section_two_description: "Our App is user friendly.",



                    section_three_title: "Find content fastet",
//                  section_three_description:       "A single place to search for your Movies, Tv shows, or LiveTV. Search and start instantly your watch session without 0 lose of your time.",
                    section_three_description:       "A single place to search for your Content.  Search and start instantly your watch session with no loss of your time.",
			
			
			
			news_section_title: "Latest News",
                    news_section_view_more: "View more",
                },

                activation: {
                    page_name: "Activation",

                    choose_plan_text: "Choose a plan that fits your needs best",
                    direct_pay: "Direct pay",
                    gift_code: "Gift code",
                    monthly: "Monthly",
                    yearly: "Yearly",
                    one_time: "One time",
                    agree_with_all: "Agree with all",
                    agree_with_all_description:
                        "You must agree to our privacy policy and terms of use before activation",
                    activate_for_qa_description:
                        "Activatee tv device by voucher code for Beta testers and LG / Samsung QA team",
                    activate_qa_button_text: "Activate",
                    payments_methods_disabled: "No payment method activated",
                    gift_code_input_placeholder: "Enter your GIFT code",
                    gift_code_input_message: "Please input your GIFT code",
                    or: "Or",
                    success_message: "Your activation was successfully done!",
                    error_message: "Invalid code",
                    button_text: "Activate",
                    qa_modal_title: "Activate",
                    qa_modal_voucher_code_input_placeholder: "Enter voucher code",
                    qa_modal_voucher_code_input_message: "Please input voucher code",
                    qa_modal_success_message: "Activate",
                    qa_modal_button_title: "Activate",
                },

                upload_playlist: {
                    page_name: "Upload playlist",
                    title: "Upload Playlist",
                    description: "Choose how you want to load your playlist into the application",
                    url: "Url",
                    xtream_account: "Xtream account",
                    mac_address: "Mac address",
                    playlist_name: "Playlist name",
                    playlist_url: "Playlist url",
                    host: "Host",
                    username: "Username",
                    password: "Password",
                    url_button_title: "Upload",
                    xtream_button_title: "upload by extream",
                    playlist_name_message: "Please input playlist name",
                    playlist_url_message: "Please input playlist url",
                    host_message: "Please input host",
                    username_message: "Please input username",
                    password_message: "Please input password",
                    success_message: "Playlist successfully added",
                },

                reseller: {
                    page_name: "Reseller",
                },

                register: {
                    page_name: "Register",
                    title: "Become a partner",
                    subtitle: "More info about partners",
                    company_input_placeholder: "Company name",
                    company_input_message: "Please input company name",
                    name_input_placeholder: "Name",
                    name_input_message: "Please input name",
                    surname_input_placeholder: "Surname",
                    surname_input_message: "Please input surname",
                    email_input_placeholder: "Email",
                    email_input_message: "Please input valid email",
                    password_input_placeholder: "Enter your password",
                    password_message: "Please input your password",
                    password_length_message: "Password must be minimum 8 characters",
                    country_select_placeholder: "Select country",
                    country_select_message: "Please select country",
                    address_input_placeholder: "Address",
                    address_input_message: "Please input address",
                    phone_input_message: "Please input phone",
                    choose_partner_type: "Please choose partner type",
                    reseller: "Reseller",
                    referral: "Referral",
                    accept_text: "Accept privacy policy and usage",
                    agree_text: "Agree with all",
                    step_one_button_title: "Next",
                    step_two_button_title: "Register",
                    select_reseller_message: "Member must be a Reseller or Referral",
                    register_success_message: "Registred",
                },

                news: {
                    page_name: "News",
                    title: "Latest News",
                },

                current_news: {
                    subtitle: "You May Be Interested Also",
                },

                contact_us: {
                    page_name: "Contact Us",
                    title: "Contact Us",
                    description: `Please Send your Mac Address for any App issue you have for faster replay from us " Thank You "`,
                    location_title: "Our location",
                    phone_title: "Phone number",
                    email_title: "Email address",
                    title_input_placeholder: "Your title",
                    title_input_message: "Please input your title",
                    email_input_placeholder: "Your email",
                    email_input_message: "Please input valid email",
                    message_input_placeholder: "Your message",
                    message_input_message: "Please input message",
                    button_title: "Send message",
                },

                terms_of_use: {
                    page_name: "Terms of use",
                },

                terms_of_online_sale: {
                    page_name: "Terms of Online Sale",
                },

                payment_error: {
                    page_name: "Payment error",

                    message:
                        "Please check with your payment provider about the status of the account or contact our support",
                },

                payment_success: {
                    page_name: "Payment success",

                    message:
                        "Your payment was done successfully. The app must be activated automatically if it's not please restart the app or contact support",
                },

                login: {
                    page_name: "Login",
                    title: "Login",
                    mac_input_placeholder: "Device Mac",
                    mac_input_message: "Please input device mac",
                    device_key_input_placeholder: "Device key or OTP",
                    device_key_input_message: "Please input device key",
                    button_text: "LOGIN",
                },

                playlist: {
                    button_text: "Add Playlists",
                    logout_button_text: "LOGOUT",
                    title: "Manage playlist",
                    url: "Url",
                    file: "File",
                    xtream_account: "Xtream account",
                    mac_address: "Mac address",
                    playlist_name: "Playlist name",
                    playlist_url: "Playlist url",
                    host: "Host",
                    username: "Username",
                    password: "Password",
                    url_button_title: "Upload",
                    xtream_button_title: "upload by extream",
                    playlist_name_message: "Please input playlist name",
                    playlist_url_message: "Please input playlist url",
                    host_message: "Please input host",
                    username_message: "Please input username",
                    password_message: "Please input password",
                    success_message: "Playlist successfully added",
                },
            },
        },
    },
};

export default web_settings;
