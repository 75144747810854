import React, { useState } from "react";
import { Form, notification } from "antd";

import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import REQUESTS from "../../api/requests";
import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import style from "./style/style.module.scss";

export default function AddPlaylistByFile({
  getPlaylists,
  deviceMac,
  onCancel,
  form
}) {

  const translation = useTranslation();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleText = (text) => {
    if (translation?.pages?.playlist?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  const onFinish = (values) => {
    if (!file) {
      notification.error({
        description: "Please select m3u8 file",
      });

      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mac", deviceMac);

    if (file) {
      formData.append("file", file);
    }

    function callback(data) {
      setLoading(false);
      setFile(null);
      getPlaylists();
      onCancel();
      form.resetFields();
    }
    function errorCallback(error) {
      setLoading(false);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  return (
    <div>
      <Form
        autoComplete="off"
        name="upload-playlist-by-file"
        form={form}
        onFinish={onFinish}
        className={style["add-modal-form"]}
      >
        <div style={{width:"100%"}}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message:handleText("playlist_name_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_name")} />
        </Form.Item>

        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: handleText("playlist_file_message"),
            },
          ]}
        >
          <InputComponent
            type="file"
            value={file}
            name={file?.name}
            placeholder={"Please select file"}
            accept=".m3u8, m3u"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </Form.Item>

        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            loading={loading}
            onClick={() => form.submit()}
            title={handleText("Upload")}
          />
        </div>
        </div>
       
      </Form>
    </div>
  );
}
