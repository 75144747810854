import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { Form, Input } from "antd";

import useTranslation from "../../hooks/useTranslation";
import ValidateMacAddress from "../../components/ValidateMacAddress";
import ButtonComponent from "../../components/ButtonComponent";

import REQUESTS from "../../api/requests";
import TEXT from "../../config/text";

import ErrorModal from "../../components/ErrorModal";

import LoginEditor from "../../editors/LoginEditor";

import styles from "./style/style.module.scss";

export default function LoginByMac() {
  const { webSettings, isAdmin } = useSelector((state) => state.globalState);

  const [macIsValid, setMacIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [captchaSiteKey, setCaptchaSieKey] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const translation = useTranslation();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.login?.[text];
    }

    return TEXT?.pages?.login?.[text];
  };

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSieKey(data);
      },
      (err) => {}
    );
  };

  function onChange(value) {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setIsValid(message.success);
      },
      (err) => setIsValid(false)
    );
  }

  const onFinish = (values) => {
    setErrorMessage(null);
    const { mac, code } = values;

    const body = {
      mac,
    };

    if (macIsValid && macIsValid.auth_type && macIsValid.auth_type === "otp") {
      body.code = code;
    } else {
      body.key = code;
    }

    REQUESTS.LOGIN_BY_MAC(
      (data) => {
        localStorage.setItem("TOKEN", data);
        navigate("/playlists");
      },

      (err) => {
        setErrorMessage(err.message);
      },
      body
    );
  };

  useEffect(() => {
    if (macIsValid && macIsValid.auth_type) {
      if (macIsValid.auth_type === "otp") {
        function callback(data) {}

        function errorCallback(err) {}

        REQUESTS.GET_OTP(callback, errorCallback, {
          mac: macIsValid.mac,
        });
      }
    }
  }, [macIsValid]);

  useEffect(() => {
    getCaptchaSiteKey();
  }, []);

  const pageStyle = {
    backgroundColor: webSettings?.pages?.login?.background_color,
    backgroundImage: `url(${webSettings?.pages?.login?.background_image})`,
    backgroundSize: "cover",
  };

  return (
    <div className={styles["page"]} style={pageStyle}>
      {isAdmin && <LoginEditor />}

      <div className={styles["login-by-mac-page"]}>
        <h3 className={styles["login-by-mac-page__title"]}>
          {handleText("title")}
        </h3>

        <Form
          form={form}
          onFinish={onFinish}
          className={styles["login-by-mac-page__form"]}
        >
          <div style={{ width: "100%" }}>
            <ValidateMacAddress setMacIsValid={setMacIsValid} />
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: handleText("device_key_input_message"),
                },
              ]}
            >
              <Input placeholder={handleText("device_key_input_placeholder")} />
            </Form.Item>

            {captchaSiteKey && (
              <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
            )}

            <Form.Item
              shouldUpdate
              className={styles["login-by-mac-page__form__btn"]}
            >
              <ButtonComponent
                title={handleText("button_text")}
                onClick={() => form.submit()}
                size="large"
                disabled={
                  captchaSiteKey
                    ? macIsValid && isValid
                      ? false
                      : true
                    : !macIsValid
                }
              />
            </Form.Item>
          </div>
        </Form>

        <ErrorModal
          message={errorMessage}
          visible={errorMessage ? true : false}
          onClose={() => setErrorMessage(null)}
        />
      </div>
    </div>
  );
}
