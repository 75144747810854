import React, { useState } from "react";
import { Form } from "antd";

import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";
import style from "./style/style.module.scss";

export default function AddPlaylistByXtream({
  getPlaylists,
  deviceMac,
  onCancel,
  form
}) {
  const translation = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mac", deviceMac);

    const url = `${values.host}&username=${values.username}&password=${values.password}&type=m3u_plus&output=ts`;

    formData.append("url", url);

    function callback() {
      setLoading(false);
      getPlaylists();
      onCancel();
      form.resetFields();
    }

    function errorCallback(error) {
      setLoading(false);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.playlist?.[text];
    }
    return TEXT?.pages?.playlist?.[text];
  };

  return (
    <div>
      <Form
        autoComplete="off"
        name="upload-playlist-by-xtreame"
        form={form}
        onFinish={onFinish}
        className={style["add-modal-form"]}
      >
        <div style={{ width: "100%" }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: handleText("playlist_name_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_name")} />
          </Form.Item>

          <Form.Item
            name="host"
            rules={[
              {
                required: true,
                message: handleText("host_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("host")} />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: handleText("username_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("username")} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                autoComplete: "off",
                required: true,
                message: handleText("password_message"),
              },
            ]}
          >
            <InputComponent
              type="password"
              placeholder={handleText("password")}
            />
          </Form.Item>

          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <ButtonComponent
              onClick={() => form.submit()}
              title={handleText("xtream_button_title")}
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
