import { useState } from "react";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import style from "../style/payment.module.scss";

export default function PaypalComponent ({
  deviceId,
  customId,
  currency,
  clientId,
  onApprove,
  type,
  selectedPackage,
}) {
  const [loading, setLoading] = useState(true);

  const onInit = () => {
    setLoading(false);
  };

  return (
    <div>
      { loading && (
        <div className={ style.loading }>
          <Spin
            indicator={
              <LoadingOutlined
                style={ {
                  fontSize: 30,
                } }
                spin
              />
            }
          />
        </div>
      ) }

      { clientId && (
        <div
          className={ style["paypal-btn-container"] }
          style={ { opacity: loading ? 0 : 1 } }
        >
          <PayPalScriptProvider
            options={ {
              "client-id": clientId,
              "vault": true,
              "disable-funding": 'card',
              "currency": currency
            } }
          >
            { type === "subscription" ? (
              <PayPalButtons
                createSubscription={ (data, actions) => {
                  return actions.subscription.create({
                    plan_id: selectedPackage?.paypal_price_id,
                    custom_id: customId,
                  });
                } }
                onApprove={ (data, actions) => {
                  return actions.order.capture().then(function (details) {
                    onApprove(data, actions);
                  });
                } }
                onError={ (error) => {
                  notification.error({
                    description: `${error}`,
                  });
                } }
                onInit={ onInit }
                style={ {
                  shape: "rect",
                  // color: "silver",
                  layout: "vertical",
                  label: "paypal",
                } }
              />
            ) : (
              <PayPalButtons

                createOrder={ (data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        description: JSON.parse(customId).dm,
                        amount: {
                          value: selectedPackage?.price,
                          currency_code: currency,
                        },
                        custom_id: customId,
                      },
                    ],
                    application_context: {
                      shipping_preference: 'NO_SHIPPING'
                    }
                  });
                } }
                onApprove={ (data, actions) => {
                  return actions.order.capture().then(function (details) {
                    onApprove(data, actions);
                  });
                } }
                onError={ (error) => {
                  notification.error({
                    description: `${error}`,
                  });
                } }
                onInit={ onInit }
                style={ {
                  shape: "rect",
                  // color: "silver",
                  layout: "vertical",
                  label: "paypal",
                } }
              />
            ) }
          </PayPalScriptProvider>
        </div>
      ) }
    </div>
  );
}
