import ERROR from "../images/error.png";
import SUCCESS from "../images/success.png";
import CHAT_BACKGROUND from "../images/chat_bg.svg";
import CMI from "../images/logo_cmi.png";
import NOT_fOUND from "../images/404.png";

/**
 * We gather all the images here from the images folder and export them to various locations.
 */

const IMAGES = {
    // a
    // b
    // c
    CHAT_BACKGROUND,
    CMI,
    // d
    // e
    ERROR,
    // f
    // g
    // h
    // i
    // j
    // k
    // l
    // m
    // n
    NOT_fOUND,
    // o
    // p
    // q
    // r
    // s
    SUCCESS,
    // t
    // u
    // v
    // w
    // x
    // y
    // z
};

export default IMAGES;
