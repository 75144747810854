const PATHS = {
    // a
    ACTIVATION: "/activation",
    // b
    // c
    CONTACT_US: "contact-us",
    CHAT:'chat',
    // d
    // e
    // f
    // g
    // h
    HOME: "home",
    // i
    INFO: "/info-link",
    // j
    // k
    // l
    LOGIN:"login",
    // m
    // n
    NEWS: "/news",
    // o
    // p
    PRIVACY: "/privacy",
    PLAYLISTS:"playlists",
    // q
    // r
    RESELLER: "reseller",
    // s
    // t
    TERMS_OF_SALE: "terms-of-sale",
    TERMS_OF_USE: "terms-of-use",
    // u
    UPLOAD_PLAYLIST: "/upload-playlist",
    // v
    // w
    // x
    // y
    // z
};

export default PATHS;
