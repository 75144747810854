import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";

import NewsCard from "../../components/NewsCard";

import CustomPagination from "../../components/CustomPagination";

import NewsEditor from "../../editors/NewsEditor";

import style from "./style/news.module.scss";
import TitleComponent from "../../components/TitleComponent";

export default function NewsPage() {
    const translation = useTranslation();

    const { webSettings } = useSelector((state) => state.globalState);

    const isAdmin = useSelector((store) => store.globalState.isAdmin);

    const [isLoading, setIsLoading] = useState(true);
    const [news, setNews] = useState([]);

    const navigate = useNavigate();

    const handleText = (text) => {
        if (translation?.pages?.news?.[text]) {
            return translation?.pages?.news?.[text];
        }

        return TEXT?.pages?.news?.[text];
    };

    const getNews = () => {
        setIsLoading(true);
        REQUESTS.GET_NEWS(
            (data) => {
                setNews(data.rows);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    };

    const clickCardHandler = (id) => {
        navigate(`/news/${id}`);
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        getNews();
    }, []);

    const pageStyle = {
        backgroundColor: webSettings?.pages?.news?.background_color,
        backgroundImage: `url(${webSettings?.pages?.news?.background_image})`,
        backgroundSize: "cover",
    };

    return (
        <div className={style["news-page"]} style={pageStyle}>
            {isAdmin && <NewsEditor />}

            <div className="container">
                <h1 className={style["news-title"]}>{handleText("title")}</h1>

                <div>
                    {isLoading ? (
                        <div>{/* Loading... */}</div>
                    ) : (
                        <>
                            <div className={style["news-cards_main"]}>
                                {news.map((item, index) => {
                                    return (
                                        <NewsCard
                                            key={item.id}
                                            news={item}
                                            cardClick={clickCardHandler}
                                        />
                                    );
                                })}
                            </div>
                            {/* <div>
                                <CustomPagination itemsPerPage={4} data={news} />
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
