import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Form } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

/**
 *  Upload playlist by Xtream account: Entering in the user's device mac address, playlist name and playlist url depend xtream account email and password.
 *  After request is shown success or error modal.
 */

export default function UploadByXTreme({ captchaSiteKey }) {
  const translation = useTranslation();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.upload_playlist?.[text]) {
      return translation?.pages?.upload_playlist?.[text];
    }

    return TEXT?.pages?.upload_playlist?.[text];
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        console.log(message, "data");
        setIsValid(message.success);
      },
      (err) => setIsValid(false)
    );
  }

  const onFinish = (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mac", values.mac);

    const url = `${values.host}&username=${values.username}&password=${values.password}&type=m3u_plus&output=ts`;

    formData.append("url", url);

    function callback() {
      setLoading(false);
      form.resetFields();
      setSuccessMessage(handleText("success_message"));
    }

    function errorCallback(error) {
      setLoading(false);
      setErrorMessage(error.message);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  useEffect(() => {
    if (searchParams.get("mac")) {
      form.setFields([{ name: "mac", value: searchParams.get("mac") }]);
    }
  }, [searchParams.get("mac")]);

  return (
    <>
      <Form
        autoComplete="off"
        name="upload-playlist-by-xtreame"
        form={form}
        onFinish={onFinish}
      >
        <ValidateMacAddress
          setMacIsValid={setMacIsValid}
          reset={successMessage}
        />

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: handleText("playlist_name_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_name")} />
        </Form.Item>

        <Form.Item
          name="host"
          rules={[
            {
              required: true,
              message: handleText("host_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("host")} />
        </Form.Item>

        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: handleText("username_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("username")} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              autoComplete: "off",
              required: true,
              message: handleText("password_message"),
            },
          ]}
        >
          <InputComponent
            type="password"
            placeholder={handleText("password")}
          />
        </Form.Item>
        {captchaSiteKey && (
          <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
        )}
        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            loading={loading}
            disabled={ captchaSiteKey
              ? macIsValid && isValid
                ? false
                : true
              :
               !macIsValid}
            onClick={() => form.submit()}
            title={handleText("xtream_button_title")}
          />
        </div>
      </Form>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />

      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
