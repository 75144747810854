import { Outlet } from "react-router-dom";

import { Layout } from "antd";

import Navbar from "./components/Navbar";
import FooterComponent from "./components/FooterComponent";

import style from "./style/index.module.scss";

export default function LayoutComponent() {
    return (
        <Layout>
            <Layout.Header className={style.header}>
                <Navbar />
            </Layout.Header>

            <Layout.Content>
                <Outlet />
            </Layout.Content>

            <Layout.Footer className={style.footer}>
                <FooterComponent />
            </Layout.Footer>
        </Layout>
    );
}
