import React, { useEffect } from "react";
import { Modal, Tabs, Form } from "antd";

import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import AddPlaylistByUrl from "./AddPlaylistByUrl";
import AddPlaylistByXtream from "./AddPlaylistByXtream";
import AddPlaylistByFile from "./AddPlaylistByFile";

export default function AddPlaylist({
  visible,
  onCancel,
  getPlaylists,
  deviceMac,
}) {
  const [form] = Form.useForm();

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <div>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        className="playlist-modal"
        footer={null}
      >
        <div>
          <h1 className="playlist-modal-title">{handleText("add_playlist_modal_title")}</h1>
          <Tabs
            centered={true}
            tabBarStyle={{ color: "white", marginTop: 20 }}
            destroyInactiveTabPane
          >
            <Tabs.TabPane key="1" tab={handleText("url")}>
              <AddPlaylistByUrl
                deviceMac={deviceMac}
                getPlaylists={getPlaylists}
                onCancel={onCancel}
                form={form}
              />
            </Tabs.TabPane>

            <Tabs.TabPane key="2" tab={handleText("xtream_account")}>
              <AddPlaylistByXtream
                deviceMac={deviceMac}
                getPlaylists={getPlaylists}
                onCancel={onCancel}
                form={form}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={handleText("file")} key="3">
              <AddPlaylistByFile
                deviceMac={deviceMac}
                getPlaylists={getPlaylists}
                onCancel={onCancel}
                form={form}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </div>
  );
}
