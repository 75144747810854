import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Form, notification } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import REQUESTS from "../../api/requests";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

/**
 *  Upload playlist by file: Entering in the user's device mac address, playlist name and choose the m3u8 file from the PC.
 *  After request is shown success or error modal.
 */

export default function UploadByFile({ captchaSiteKey }) {
  const translation = useSelector((store) => store.globalState.translation);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm();

  function onChange(value) {
    console.log("Captcha value:", value);
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        console.log(message, "data");
        setIsValid(message.success);
      },
      (err) => setIsValid(false)
    );
  }

  const onFinish = (values) => {
    if (!file) {
      notification.error({
        description: "Please select m3u8 file",
      });

      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mac", values.mac);

    if (file) {
      formData.append("file", file);
    }

    function callback(data) {
      setLoading(false);
      setFile(null);
      form.resetFields();

      setSuccessMessage("Playlist successfully added");
    }
    function errorCallback(error) {
      setLoading(false);

      setErrorMessage(error.message);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  const onFinishFailed = (error) => {
    setLoading(false);
  };

  useEffect(() => {
    if (searchParams.get("mac")) {
      form.setFields([{ name: "mac", value: searchParams.get("mac") }]);
    }
  }, [searchParams.get("mac")]);
  return (
    <>
      <Form
        autoComplete="off"
        name="upload-playlist-by-file"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <ValidateMacAddress
          setMacIsValid={setMacIsValid}
          reset={successMessage}
        />

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input playlist name!",
            },
          ]}
        >
          <InputComponent placeholder="Playlist name" />
        </Form.Item>

        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: "Please select m3u8 file!",
            },
          ]}
        >
          <InputComponent
            type="file"
            value={file}
            name={file?.name}
            placeholder={"Please select file"}
            accept=".m3u8, m3u"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </Form.Item>
        {captchaSiteKey && (
          <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
        )}
        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            loading={loading}
            disabled={
              captchaSiteKey
                ? macIsValid && isValid
                  ? false
                  : true
                : !macIsValid
            }
            onClick={() => form.submit()}
            title={"Upload"}
          />
        </div>
      </Form>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />

      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
