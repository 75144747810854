import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ICONS } from "../../config/icons";
import REQUESTS from "../../api/requests";
import TEXT from "../../config/text";
import AddPlaylist from "./AddPlaylist";

import Playlist from "./Playlist";
import useTranslation from "../../hooks/useTranslation";

import style from "./style/style.module.scss";
import PlaylistEditor from "../../editors/PlaylistEditro";

export default function PlaylistsPage() {
    const { webSettings, isAdmin } = useSelector((state) => state.globalState);

    const translation = useTranslation();

    const [device, setDevice] = useState([]);
    const [deviceMac, setDeviceMac] = useState(null);

    const [visibleAddPlaylistModal, setVisibleAddPlaylistModal] = useState(false);

    const handleText = (text) => {
        if (translation?.pages?.login?.[text]) {
            return translation?.pages?.playlist?.[text];
        }

        return TEXT?.pages?.playlist?.[text];
    };

    const getPlaylists = () => {
        REQUESTS.GET_PLAYLISTS(
            (data) => {
                setDevice(data);
                setDeviceMac(data.mac);
            },
            (err) => {}
        );
    };

    useEffect(() => {
        getPlaylists();
    }, []);

    const pageStyle = {
        backgroundColor: webSettings?.pages?.playlist?.background_color,
        backgroundImage: `url(${webSettings?.pages?.playlist?.background_image})`,
        backgroundSize: "cover",
    };

    return (
        <div className={style["page"]} style={pageStyle}>
            {isAdmin && <PlaylistEditor />}
            <div className={style["playlist-page-container"]}>
                <h1 className={style["title"]}>{handleText("title")}</h1>
                <div className={style["playlists-container"]}>
                    {device.playlists &&
                        device.playlists.map((playlist) => {
                            return (
                                <Playlist
                                    playlist={playlist}
                                    deviceId={device.id}
                                    getPlaylists={getPlaylists}
                                    key={playlist.id}
                                />
                            );
                        })}

                    <div
                        className={style["playlist-add-btn"]}
                        onClick={() => setVisibleAddPlaylistModal(true)}
                    >
                        {ICONS.ADD}
                        <span> {handleText("button_text")}</span>
                    </div>
                </div>
                <AddPlaylist
                    visible={visibleAddPlaylistModal}
                    onCancel={() => setVisibleAddPlaylistModal(false)}
                    getPlaylists={getPlaylists}
                    deviceMac={deviceMac}
                />
            </div>
        </div>
    );
}
