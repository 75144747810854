import React from "react";
import { Form, Input, Modal } from "antd";

import ButtonComponent from "../../components/ButtonComponent";
import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";
import TEXT from "../../config/text";

import "./style/modal.scss";
import style from "./style/style.module.scss";

export default function EditPlaylist({
  visible,
  onCancel,
  getPlaylists,
  deviceId,
  playlist,
}) {
  const [form] = Form.useForm();

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  const onFinish = (values) => {
    const body = { id: playlist.id, deviceId, ...values };

    function callback(data) {
      getPlaylists();
      onCancel();
    }

    function errorCallback() {}

    REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);
  };

  return (
    <div>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        className="playlist-modal"
        footer={null}
      >
        <div>
          <h1 className="playlist-modal-title">{handleText("edit_playlist_modal_title")}</h1>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ name: playlist.name, url: playlist.url }}
            className={style["edit-modal-form"]}
          >
            <div style={{ width: "100%" }}>
              <Form.Item
                name="name"
                initialValue={{
                  name: playlist.name,
                  url: playlist.url,
                }}
                rules={[
                  {
                    required: true,
                    message: handleText("code_input_placeholder"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: handleText("code_input_placeholder"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div style={{ marginTop: "40px", textAlign: "center" }}>
                <ButtonComponent title={handleText("SAVE")} onClick={() => form.submit()} />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
