import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";

import Card from "./components/Card.jsx";
import NewsCard from "../../components/NewsCard";
import ButtonComponent from "../../components/ButtonComponent";

import HomeEditor from "../../editors/HomeEditor";

import style from "./style/home.module.scss";

/**
 * The home page is intended for the presentation of the application, from the first part of the page we can go to the activation and upload playlist pages.
 * In other sections used the Card component, by sending text and an illustration to the component
 *
 * @returns Home Page
 */

export default function HomePage() {
  const navigate = useNavigate();

  const translation = useTranslation();

  const isAdmin = useSelector((state) => state.globalState.isAdmin);
  const widgets = useSelector((state) => state.globalState.widgets);
  const webSettings = useSelector((state) => state.globalState.webSettings);

  const [news, setNews] = useState([]);

  const clickCardHandler = (id) => {
    navigate(`/news/${id}`);
  };

  const getNews = () => {
    REQUESTS.GET_LAST_NEWS(
      (data) => {
        setNews(data.rows);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    getNews();
  }, []);

  const handleText = (text) => {
    if (translation?.pages?.home?.[text]) {
      return translation?.pages?.home?.[text];
    }

    return TEXT?.pages?.home?.[text];
  };

  const headerStyle = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.header &&
      webSettings.pages.home.header.background_color,
    backgroundImage:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.header &&
      `url(${webSettings.pages.home.header.background_image})`,
  };

  const sectionOneStyle = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_one &&
      webSettings.pages.home.section_one.background_color,
    backgroundImage:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_one &&
      `url(${webSettings.pages.home.section_one.background_image})`,
  };

  const sectionTwoBackground = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_two &&
      webSettings.pages.home.section_two.background_color,
    backgroundImage:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_two &&
      `url(${webSettings.pages.home.section_two.background_image})`,
  };

  const sectionThreeStyle = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_three &&
      webSettings.pages.home.section_three.background_color,
    backgroundImage:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.section_three &&
      `url(${webSettings.pages.home.section_three.background_image})`,
  };

  const newsSectionStyle = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.news_section &&
      webSettings.pages.home.news_section.background_color,
    backgroundImage:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.news_section &&
      `url(${webSettings.pages.home.news_section.background_image})`,
  };

  return (
    <div className={style["header-main"]}>
      {isAdmin && <HomeEditor />}

      <div className={style["image-wrapper"]} style={headerStyle}>
        <div className={style["container"]}>
          <div className={style["card-text"]}>
            <h1>{handleText("header_title")}</h1>
            <h5>{handleText("header_eur_to_mad_infomation")}</h5>
            <h2>{handleText("header_second_title")}</h2>
            <h4>{handleText("header_description")}</h4>
          </div>

          <div className={style["btn-container"]}>
            <ButtonComponent
              onClick={() => navigate(PATHS.ACTIVATION)}
              title={handleText("header_activate_app_button")}
            />
            {widgets &&
              widgets.hide_upload_playlist &&
              widgets.hide_upload_playlist.enabled &&
              widgets.hide_upload_playlist.enabled === "false" && (
                <ButtonComponent
                  onClick={() => navigate(PATHS.UPLOAD_PLAYLIST)}
                  title={handleText("header_upload_playlist_button")}
                />
              )}
          </div>
        </div>
      </div>

      <section
        className={style["multi-functional_section"]}
        style={sectionOneStyle}
      >
        <Card
          image={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_one &&
            webSettings.pages.home.section_one.icon
          }
          iconSize={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_one &&
            webSettings.pages.home.section_one.icon_size
          }
          title={handleText("section_one_title")}
          text={handleText("section_one_description")}
        />
      </section>

      <section
        className={style["watch-live_section"]}
        style={sectionTwoBackground}
      >
        <Card
          bg={true}
          reverse={true}
          image={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_one &&
            webSettings.pages.home.section_two &&
            webSettings.pages.home.section_two.icon
          }
          iconSize={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_one &&
            webSettings.pages.home.section_two &&
            webSettings.pages.home.section_two.icon_size
          }
          title={handleText("section_two_title")}
          text={handleText("section_two_description")}
        />
      </section>

      <section
        className={style["find-content_section"]}
        style={sectionThreeStyle}
      >
        <Card
          image={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_three &&
            webSettings.pages.home.section_three.icon
          }
          iconSize={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.section_three &&
            webSettings.pages.home.section_three.icon_size
          }
          title={handleText("section_three_title")}
          text={handleText("section_three_description")}
        />
      </section>

      {widgets?.news?.enabled === "true" && (
        <section className={style["news-section"]} style={newsSectionStyle}>
          <div className={style["news-container"]}>
            <h2 className={style["news-title"]}>
              {handleText("news_section_title")}
            </h2>
            <div className={style["news-cards_wrapper"]}>
              {news.map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    news={item}
                    cardClick={clickCardHandler}
                  />
                );
              })}
            </div>
            <NavLink to={PATHS.NEWS} className={style["news-view_more"]}>
              {handleText("news_section_view_more")}
            </NavLink>
          </div>
        </section>
      )}
    </div>
  );
}
