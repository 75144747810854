import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import useTranslation from "../hooks/useTranslation";
import { Form, Input } from "antd";

import TEXT from "../config/text";
import REQUESTS from "../api/requests";


/**
 *
 * @param {boolean} reset           In order to reset the component state
 * @param {func} setMacIsValid      Տo pass the mac address status to the parent
 * @param {bool} appColor           To match the prevailing hue
 *
 * @returns Input
 */

export default function ValidateMacAddress({
  defaultMac,
  reset,
  setMacIsValid,
  appColor,
}) {
  const translation = useTranslation();
  const [value, setValue] = useState("");

  const [help, setHelp] = useState("");
  const [validateStatus, setValidateStatus] = useState("");

  const handleText = (text) => {
      if (translation?.general?.[text]) {
          return translation?.general?.[text];
      }

      return TEXT?.general?.[text];
  };

  const validateMacAddress = () => {
      setValidateStatus("");
      setHelp("");

      if (value.length === 0) {
          return;
      }

      setValidateStatus("validating");

      function callback(data) {
        console.log(data)
          setValidateStatus("success");
          setMacIsValid(data);
          setHelp(handleText("mac_input_valid_message"));
      }

      function errorCallback() {
          setValidateStatus("error");
          setMacIsValid(false);
          setHelp(handleText("mac_input_not_valid_message"));
      }

      REQUESTS.VALIDATE_MAC(value, callback, errorCallback);
  };

  const resetInfo = () => {
      setHelp("");
      setValue("");
      setValidateStatus("");
  };

  useEffect(() => {
      const timeout = setTimeout(() => {
          validateMacAddress();
      }, 1000);

      return () => {
          clearTimeout(timeout);
      };
  }, [value]);

  useEffect(() => {
      resetInfo();
  }, [reset]);

  useEffect(() => {
      if (defaultMac) {
          setValue(defaultMac);
      }
  }, [defaultMac]);


  return (
    <Form.Item
    name="mac"
    hasFeedback={true}
    help={value ? help : undefined}
    required
    rules={[
        {
            required: true,
            message: handleText("mac_input_message"),
        },
    ]}
    validateStatus={validateStatus}
>
    <Input
        value={value}
        onChange={(e) => {
            setValue(e.target.value);
        }}
        className={`mac-validation ${appColor && "app-color"}`}
        placeholder={handleText("mac_input_placeholder")}
        style={{
            background: "transparent",
            border: "none",
            borderBottom: "1px solid red",
            color: "white",
        }}
    />
</Form.Item>

  );
}

ValidateMacAddress.propTypes = {
  appColor: PropTypes.bool,
  reset: PropTypes.bool,
  setMacIsValid: PropTypes.func.isRequired,
};
