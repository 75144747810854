const TEXT = {
    general: {
        all_rights_reserved: "All Rights Reserved",
        close: "Close",
        mac_input_message: "Please input your mac address",
        mac_input_valid_message: "Mac address is valid",
        mac_input_not_valid_message: "Mac address is not valid",
        mac_input_placeholder: "Mac address",
        login: "Login",
    },

	
// sha suppression des references a live tv pour passer encaissement samsung  2023/05/04
    pages: {
        home: {
            page_name: "Home",

            page_name: "Home",
            header_title: "Pay once and activate your player lifetime at 4 euros only ",
            header_second_title: "Try our Player on Samsung & LG devices  for 7 Days FREE",
            header_eur_to_mad_infomation: " 4 EURO ≈ 44 MAD     ( 1 Euro ≈ 11 Moroccan Dirham )",
            header_description: "Important Notice! We do not provide any content of any kind with this App therefore you must have your own playlist to use it",
            header_activate_app_button: "Activate app",
            header_upload_playlist_button: "Upload playlist",

            section_one_title: "Launch Your  Player for 7 Days FREE",
//            section_one_description: "It is the best solution on the market for better experiencing your playlists of TV channels, films, and series, and is available in the stores of Samsung & LG TVs",
           section_one_description: "Pay once and activate your player lifetime at 4 euros only",
//            section_two_title: "Watch Live TV with comfort",
            section_two_title: "",
		
//            section_two_description: "Our good organized UI will bring you a new level of watching live tv programs. Pick a channel, Watch, Save to favorites, and the app will start suggesting to you what you like to watch.",
section_two_description: "Our player is a 100% user friendly",
		

            section_three_title: "Find and browse your content faster",
//            section_three_description:       "A single place to search for your Movies, Tv shows, or LiveTV. Search and start instantly your watch session without 0 lose of your time.",
            section_three_description:       "A single place to browse your Content. Search and start instantly watching session with lossless time",


            news_section_title: "Latest News",
            news_section_view_more: "View more",
        },

        activation: {
            page_name: "Activation",

            choose_plan_text: "Choose a plan that fits your needs best",
            direct_pay: "Direct pay",
            gift_code: "Gift code",
            monthly: "Monthly",
            yearly: "Yearly",
            one_time: "One time",
            agree_with_all: "Agree with all",
            agree_with_all_description:             "You must agree to our privacy policy and terms of use before activation",
            activate_for_qa_description:              "Activatee tv device by voucher code for Beta testers and LG / Samsung QA team",
            activate_qa_button_text: "Activate",
            payments_methods_disabled: "No payment method activated",

            gift_code_input_placeholder: "Enter your GIFT code",
            gift_code_input_message: "Please input your GIFT code",
            or: "Or",

            success_message: "Your activation was successfully done!",
            error_message: "Invalid code",

            button_text: "Activate",

            qa_modal_title: "Activate",
            qa_modal_voucher_code_input_placeholder: "Enter voucher code",
            qa_modal_voucher_code_input_message: "Please input voucher code",
            qa_modal_success_message: "Activate",
            qa_modal_button_title: "Activate",
        },

        upload_playlist: {
            page_name: "Upload playlist",
            title: "Upload Playlist",
            description: "Choose how you want to load your playlist into the application",
            url: "Url",
            xtream_account: "Xtream account",
            mac_address: "Mac address",
            playlist_name: "Playlist name",
            playlist_url: "Playlist url",
            host: "Host",
            username: "Username",
            password: "Password",
            url_button_title: "Upload",
            xtream_button_title: "upload by xtream",
            playlist_name_message: "Please input playlist name",
            playlist_url_message: "Please input playlist url",
            host_message: "Please input host",
            username_message: "Please input username",
            password_message: "Please input password",
            success_message: "Playlist successfully added",
            other:"Other M3u8"
        },

        reseller: {
            page_name: "Reseller",
        },

        register: {
            page_name: "Register",
            title: "Become a partner",
            subtitle: "More info about partners",
            company_input_placeholder: "Company name",
            company_input_message: "Please input company name",
            name_input_placeholder: "Name",
            name_input_message: "Please input name",
            surname_input_placeholder: "Surname",
            surname_input_message: "Please input surname",
            email_input_placeholder: "Email",
            email_input_message: "Please input valid email",
            password_input_placeholder: "Enter your password",
            password_message: "Please input your password",
            password_length_message: "Password must be minimum 8 characters",
            country_select_placeholder: "Select country",
            country_select_message: "Please select country",
            address_input_placeholder: "Address",
            address_input_message: "Please input address",
            phone_input_message: "Please input phone",
            choose_partner_type: "Please choose partner type",
            reseller: "Reseller",
            referral: "Referral",
            accept_text: "Accept privacy policy and usage",
            agree_text: "Agree with all",
            step_one_button_title: "Next",
            step_two_button_title: "Register",
            select_reseller_message: "Member must be a Reseller or Referral",
            register_success_message: "Registred",
        },

        news: {
            page_name: "News",
            title: "Latest News",
        },

        current_news: {
            subtitle: "You May Be Interested Also",
        },

        contact_us: {
            page_name: "Contact Us",
            title: "Contact Us",
            description: `Please Send your Mac Address for any App issue you have for faster replay from us " Thank You "`,
            location_title: "Our location",
            phone_title: "Phone number",
            email_title: "Email address",
            title_input_placeholder: "Your title",
            title_input_message: "Please input your title",
            email_input_placeholder: "Your email",
            email_input_message: "Please input valid email",
            message_input_placeholder: "Your message",
            message_input_message: "Please input message",
            button_title: "Send message",
        },

        terms_of_use: {
            page_name: "Terms of use",
        },

        terms_of_online_sale: {
            page_name: "Terms of Online Sale",
        },

        payment_error: {
            page_name: "Payment error",

            message:
                "Please check with your payment provider about the status of the account or contact our support",
        },

        payment_success: {
            page_name: "Payment success",

            message:
                "Your payment was done successfully. The app must be activated automatically if it's not please restart the app or contact support",
        },

        login: {
            page_name: "Login",
            title: "Login",
            mac_input_placeholder: "Device Mac",
            mac_input_message: "Please input device mac",
            device_key_input_placeholder: "Device key or OTP",
            device_key_input_message: "Please input device key",
            button_text: "LOGIN",
        },

        playlist: {
            button_text: "Add Playlists",
            logout_button_text: "LOGOUT",
            title: "Manage playlist",
            url: "Url",
            file: "File",
            xtream_account: "Xtream account",
            mac_address: "Mac address",
            playlist_name: "Playlist name",
            playlist_url: "Playlist url",
            host: "Host",
            username: "Username",
            password: "Password",
            url_button_title: "Upload",
            xtream_button_title: "upload by extream",
            playlist_name_message: "Please input playlist name",
            playlist_url_message: "Please input playlist url",
            playlist_file_message: "Please select m3u8 file!",
            host_message: "Please input host",
            username_message: "Please input username",
            password_message: "Please input password",
            success_message: "Playlist successfully added",
            add_playlist_modal_title:"Add new playlist",
            edit_playlist_modal_title:"Edit playlist",
            delete_playlist_modal_title:"Are you sure you want to delete?",
            "SAVE":"SAVE",
            "Upload":"Upload",
        },

        chat:{
            page_name:"Chat",
            chat_start_text:"We are online and ready to help!",
            title_one:"Connecting...",
            title_two:"Admin",
            message_input_placeholder:"Enter Message",
        }
    },
};

export default TEXT;
