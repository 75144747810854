import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";
export default function OtherM3U8({ captchaSiteKey }) {
  const translation = useTranslation();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [successMessage, setSuccessMessage] = useState(null);

  const [defaultMac, setDefaultMac] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.upload_playlist?.[text]) {
      return translation?.pages?.upload_playlist?.[text];
    }

    return TEXT?.pages?.upload_playlist?.[text];
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        console.log(message, "data");
        setIsValid(message.success);
      },
      (err) => setIsValid(false)
    );
  }

  const onFinish = (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mac", values.mac);
    formData.append("url", values.url);

    function callback() {
      setLoading(false);
      form.resetFields();
      setSuccessMessage(handleText("success_message"));
    }

    function errorCallback(error) {
      setLoading(false);
      setErrorMessage(error.message);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  useEffect(() => {
    if (searchParams.get("mac")) {
      setDefaultMac(searchParams.get("mac"));
      form.setFields([{ name: "mac", value: searchParams.get("mac") }]);
    }
  }, [searchParams.get("mac")]);

  return (
    <>
      <Form
        autoComplete="off"
        name="upload-playlist-by-url"
        form={form}
        onFinish={onFinish}
      >
        <ValidateMacAddress
          defaultMac={defaultMac}
          setMacIsValid={setMacIsValid}
          reset={successMessage}
        />

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: handleText("playlist_name_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_name")} />
        </Form.Item>

        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: handleText("playlist_url_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_url")} />
        </Form.Item>

        {captchaSiteKey && (
          <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
        )}

        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            disabled={
              captchaSiteKey
                ? macIsValid && isValid
                  ? false
                  : true
                : !macIsValid
            }
            loading={loading}
            onClick={() => form.submit()}
            title={handleText("url_button_title")}
          />
        </div>
      </Form>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />

      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
