import { Button } from "antd";
import PropTypes from "prop-types";
import mod from "./style/buttonComponent.module.scss";

export default function ButtonComponent({ type, title, onClick, loading, disabled, size }) {
    return (
        <Button
            type={type}
            loading={loading}
            onClick={onClick}
            disabled={disabled}
            className={`${mod["btn"]}  ${size === "large" ? mod["large"] : ""}`}
        >
            {title}
        </Button>
    );
}

ButtonComponent.propTypes = {
    type: PropTypes.string,
    describe: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
