import request from "./request";
import URLS from "./url";

const REQUESTS = {
  // a
  APP_INFO: (callback) => {
    request("get", URLS.APP_INFO, {}, callback);
  },
  ACTIVATE_VOUCHER_CODE: (body, callback, errorCallback) => {
    request("post", URLS.ACTIVATE_VOUCHER_CODE, body, callback, errorCallback);
  },

  // b
  // c
  CHAT_LOGIN: (callback, errorCallback, body) =>
    request(
      "post",
      `${URLS.CHAT_LOGIN}?device=web_device`,
      body,
      callback,
      errorCallback
    ),

  COUPON_ACTIVATION: (body, callback, errorCallback) => {
    request("post", URLS.COUPON_ACTIVATION, body, callback, errorCallback);
  },

  CAPTCHA: {
    GET_SITE_KEY: (callback, errorCallback) => {
      request("get", URLS.CAPTCHA, {}, callback, errorCallback);
    },
    SENT_CAPTCHA_TOKEN: (body, callback, errorCallback) => {
      request("post", URLS.CAPTCHA, body, callback, errorCallback);
    },
  },

  // d
  // e
  // f
  // g
  GET_PACKAGES: (callback, errorCallback) => {
    request("get", URLS.GET_PACKAGES, {}, callback, errorCallback);
  },
  GET_WIDGETS: (callback, errorCallback) =>
    request("get", URLS.GET_WIDGETS, {}, callback, errorCallback),
  GET_STRIPE_SECRET_KEY: (callback, errorCallback, packageId, mac) =>
    request(
      "get",
      `${URLS.STRIPE_SECRET_KEY}?package_id=${packageId}&device_mac=${mac}`,
      {},
      callback,
      errorCallback
    ),
  GET_OTP: (callback, errorCallback, body) => {
    request("post", URLS.GET_OTP, body, callback, errorCallback);
  },

  // GET_PLAYLISTS: (callback, errorCallback) => {
  //   const TOKEN = localStorage.getItem("TOKEN");

  //   request(
  //     "get",
  //     `${URLS.GET_PLAYLISTS}?token=${TOKEN}`,
  //     {},
  //     callback,
  //     errorCallback
  //   );
  // },
  GET_PLAYLISTS: (callback, errorCallback) => {
    request(
      "get",
      `${URLS.GET_PLAYLISTS}`,
      {},
      callback,
      errorCallback,
      null,
      true
    );
  },
  // h
  // i
  IMAGE_UPLOAD: (file, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("value", file);

    request("post", URLS.IMAGE_UPLOAD, formData, callback, errorCallback);
  },
  // j
  // k
  // l
  LANGUAGES: (callback, errorCallback) => {
    request("get", URLS.LANGUAGES, {}, callback, errorCallback);
  },
  LOGIN_BY_MAC: (callback, errorCallback, body) => {
    request("post", URLS.LOGIN_BY_MAC, body, callback, errorCallback);
  },
  // m
  MESSAGES: {
    GET: (callback, errorCallback) =>
      request("get", URLS.MESSAGES, {}, callback, errorCallback),
    SEND: (callback, errorCallback, body) =>
      request("post", URLS.MESSAGES, body, callback, errorCallback),
  },
  // n
  // o
  // p
  PAYMENT: (body, callback, errorCallback) => {
    request("post", URLS.PAYMENT, body, callback, errorCallback);
  },

  PAYMENT_SETTINGS: (callback, errorCallback) => {
    request("get", URLS.PAYMENT_SETTINGS, {}, callback, errorCallback);
  },
  PAY_WITH_CMI: (body, callback, errorCallback) => {
    request("post", URLS.PAY_WITH_CMI, body, callback, errorCallback);
  },

  PLAYLIST: {
    ADD: (body, callback, errorCallback) => {
      const TOKEN = localStorage.getItem("TOKEN");
      request(
        "post",
        `${URLS.PLAYLIST}?token=${TOKEN}`,
        body,
        callback,
        errorCallback
      );
    },
    EDIT: (body, callback, errorCallback) => {
      const TOKEN = localStorage.getItem("TOKEN");
      request(
        "put",
        `${URLS.PLAYLIST}?token=${TOKEN}`,
        body,
        callback,
        errorCallback
      );
    },
    DELETE: (body, callback, errorCallback) => {
      const TOKEN = localStorage.getItem("TOKEN");
      request(
        "delete",
        `${URLS.PLAYLIST}?token=${TOKEN}`,
        body,
        callback,
        errorCallback
      );
    },
  },

  GET_NEWS: (callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + "?sort=" + JSON.stringify(["ID", "DESC"]),
      {},
      callback,
      errorCallback
    );
  },

  GET_LAST_NEWS: (callback, errorCallback) => {
    request("get", URLS.GET_NEWS + "?limit=3", {}, callback, errorCallback);
  },

  GET_CURRENT_NEWS: (id, callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + `?filter=${JSON.stringify({ id: id })}`,
      {},
      callback,
      errorCallback
    );
  },
  GET_RELATED_NEWS: (id, callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + `?filter_notin=${JSON.stringify({ id: id })}`,
      {},
      callback,
      errorCallback
    );
  },
  SEND_EMAIL: (body, callback, errorCallback) => {
    request("post", URLS.SEND_EMAIL, body, callback, errorCallback);
  },
  PLAYLIST_WITH_MAC: (body, callback, errorCallback) => {
    request("post", URLS.PLAYLIST_WITH_MAC, body, callback, errorCallback);
  },
  PLAYLIST_VALIDATION: (body, callback, errorCallback) => {
    request("post", URLS.PLAYLIST_VALIDATION, body, callback, errorCallback);
  },
  // q
  // r
  RESELLER: (body, callback, errorCallback) => {
    request("post", URLS.RESELLER, body, callback, errorCallback);
  },
  // s
  // t
  TRANSLATIONS: (id, callback) => {
    request("get", URLS.TRANSLATIONS + `?id=${id}`, {}, callback);
  },
  // u
  UPLOAD_PLAYLIST: (formData, callback, errorCallback) => {
    request("post", URLS.UPLOAD_BY_URL, formData, callback, errorCallback);
  },

  // v

  VALIDATE_MAC: (mac, callback, errorCallback) => {
    request("get", `${URLS.VALIDATE_MAC}${mac}`, {}, callback, errorCallback);
  },

  GET_EDITOR_CONFIGS: (callback, errorCallback) => {
    request("get", URLS.GET_EDITOR_CONFIGS, {}, callback, errorCallback);
  },

  UPDATE_EDITOR_CONFIGS: (body, callback, errorCallback) => {
    request("put", URLS.UPDATE_EDITOR_CONFIGS, body, callback, errorCallback);
  },

  VALIDATE_TOKEN: (body, callback, errorCallback) => {
    request("post", URLS.VALIDATE_TOKEN, body, callback, errorCallback);
  },

  // w
  // x
  // y
  // z
};

export default REQUESTS;
