import React, { useEffect, useState } from "react";
import { Form } from "antd";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";

import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import style from "./style/style.module.scss";

export default function AddPlaylistByUrl({
  deviceMac,
  getPlaylists,
  onCancel,
  form
}) {
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.playlist?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  const onFinish = (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("url", values.url);
    formData.append("mac", deviceMac);

    function callback() {
      setLoading(false);
      getPlaylists();
      onCancel();
      form.resetFields();
    }

    function errorCallback(error) {
      setLoading(false);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} className={style["add-modal-form"]}>
        <div style={{ width: "100%" }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: handleText("playlist_name_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_name")} />
          </Form.Item>

          <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: handleText("playlist_url_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_url")} />
          </Form.Item>

          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <ButtonComponent
              onClick={() => form.submit()}
              loading={loading}
              title={handleText("url_button_title")}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
