import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";

import { actions } from "../store";

import BlockComponent from "./components/BlockComponent";
import ImageComponent from "./components/ImageComponent";

import InputTextareaComponent from "./components/InputTextareaComponent";

export default function FooterEditor({}) {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const onChangePaymentsCards = (e) => {
        let configs = { ...webSettings };

        configs.footer.payments_cards.enabled = e;

        setWebSettings(configs);
    };

    const onChangeSocialLink = (e, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].link = e;

        setWebSettings(configs);
    };

    const changeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.footer.background_color = color;

        setWebSettings(configs);
    };

    const onChangeSocialLinksEnabled = (e, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].enabled = e;

        setWebSettings(configs);
    };

    const updateImage = (image, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].icon = image;

        setWebSettings(configs);
    };

    const uploadPaymentsImage = (image) => {
        let configs = { ...webSettings };

        configs.footer.payments_cards.image = image;

        setWebSettings(configs);
    };

    return (
        <EditorDrawer title={"Footer settings"} footer>
            <ColorComponent
                text="Background color"
                defaultColor={webSettings?.footer?.background_color}
                callback={changeBackgroundColor}
            />

            {webSettings?.footer?.social_links?.map((item, index) => {
                return (
                    <BlockComponent
                        key={index}
                        onOff
                        title={item.name}
                        checked={item.enabled}
                        onChange={(e) => onChangeSocialLinksEnabled(e, index)}
                    >
                        <ImageComponent
                            title={"Icon"}
                            defaultImage={item?.icon}
                            updateImage={(e) => updateImage(e, index)}
                            deleteImage={() => updateImage("", index)}
                        />

                        <InputTextareaComponent
                            title="Link"
                            rows={1}
                            value={item?.link}
                            onChange={(e) => onChangeSocialLink(e, index)}
                        />
                    </BlockComponent>
                );
            })}

            <BlockComponent
                onOff
                title="Payments cards"
                checked={webSettings?.footer?.payments_cards?.enabled}
                onChange={onChangePaymentsCards}
            >
                <ImageComponent
                    title={"Image"}
                    disabled={!webSettings?.footer?.payments_cards?.enabled}
                    defaultImage={webSettings?.footer?.payments_cards?.image}
                    updateImage={(e) => uploadPaymentsImage(e)}
                    deleteImage={() => uploadPaymentsImage("")}
                />
            </BlockComponent>
        </EditorDrawer>
    );
}
