import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./_referring-pages.scss";

export default function TermsOfSales() {
    const { appInfo } = useSelector((store) => store.globalState);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    });

    return (
        <div className="referring-pages">
            <div
                style={{
                    marginTop: "30px",
                    paddingTop: 30,
                }}
            >
                <div>
                    <h1>TERMS OF ONLINE SALE</h1>
                    <p>
                        We invite you to read these terms of Hiber Deals company on {} site. The
                        validation of your online transaction form is considered as an irrevocable
                        acceptance of these terms.
                    </p>
                </div>

                <div>
                    <h1>1. Preamble</h1>
                    <p>
                        The following online sales terms govern all transactions established through
                        Hiber Deals company on {appInfo.app_name} site. Any transaction processed
                        through this web site assumes the unconditional and irrevocable acceptance
                        of these conditions by the customer.
                    </p>
                    <h1>2. Purpose</h1>
                    <p>
                        These terms are intended to define the rights and obligations of the parties
                        in the context of the online sales done via Hiber Deals company on{" "}
                        {appInfo.app_name}
                        site.
                    </p>
                </div>

                <div>
                    <h1>3. Sales</h1>

                    <p>
                        The customer connects to Hiber Deals company on {appInfo.app_name} site and
                        selects subscriptions that he wishes to buy. He then fills his identity
                        information, accepts the terms and clicks on the ìPayî button. The customer
                        is thus redirected to the Centre MonÈtique Interbancaire online secure
                        payment platform where he completes his payment information and validates
                        the payment.
                    </p>
                </div>

                <div>
                    <h1>4. Payment Methods</h1>

                    <p>
                        To pay on Internet by credit card, the customer is called to enter his
                        payment card credentials on the secure payment page. When the transaction is
                        accepted by the bank, the settlement to debit his account is made in the
                        working-day following the date of the transaction confirmation. Online
                        payment with credit cards is secured by the Centre MonÈtique Interbancaire
                        that provides a fully secure payment service. The customer guarantees
                        Hiber Deals company on {appInfo.app_name} site that he has enough funds
                        required when using the payment method chosen by him during the payment
                        validation. When paying by credit card, the conditions of this payment
                        method that are contained in the agreements established between the customer
                        and his issuer bank, and between Hiber Deals company on {appInfo.app_name}{" "}
                        site and his bank, are applied.
                    </p>
                </div>

                <div>
                    <h1>5. Data Privacy</h1>

                    <p>
                        The information requested from the customer during the online payment are
                        required to process the online transaction. They are treated confidentially
                        by (CMI - Le centre monÈtique interbancaire). The customer has the right to
                        consult or rectify his personal data by sending a request via following
                        email address{" "}
                        <a href={`mailto:${appInfo.email}`} target="_blank">
                            {appInfo.email}
                        </a>
                    </p>
                </div>

                <div>
                    <h1>6. Proof of payment</h1>

                    <p>
                        The data stored on the Centre MonÈtique Interbancaire online platform for
                        the account of the Hiber Deals company on {appInfo.app_name} site are
                        considered as proof of payment for the online transaction made by the
                        customer.
                    </p>
                </div>
            </div>
        </div>
    );
}
