import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import useTranslation from "../../hooks/useTranslation";

import NewsCard from "../../components/NewsCard";

import { formatDate } from "../../utils";

import Loading from "../../components/Loading";

import REQUESTS from "../../api/requests";

import NewEditor from "../../editors/NewEditor";

import style from "./style/news.module.scss";
import TEXT from "../../config/text";

export default function CurrentPage() {
    const translation = useTranslation();
    const { webSettings } = useSelector((store) => store.globalState);

    const isAdmin = useSelector((store) => store.globalState.isAdmin);

    const { id } = useParams();

    const navigate = useNavigate();

    const [news, setNews] = useState([]);
    const [currentNews, setCurrentNews] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const handleText = (text) => {
        if (translation?.pages?.upload_playlist?.[text]) {
            return translation?.pages?.upload_playlist?.[text];
        }

        return TEXT?.pages?.upload_playlist?.[text];
    };

    const setDefaultImage = (e) => {
        e.target.src = "assets/images/default.png";
    };

    const clickCardHandler = (id) => {
        navigate(`/news/${id}`);
    };

    const getCurrentNews = () => {
        REQUESTS.GET_CURRENT_NEWS(
            id,
            (data) => {
                if (data.rows.length === 0) {
                    navigate("/news");
                    return;
                }
                setCurrentNews(data.rows[0]);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    };

    const getRelatedNews = () => {
        REQUESTS.GET_RELATED_NEWS(
            id,
            (data) => {
                setNews(data.rows);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        getCurrentNews();
        getRelatedNews();
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, [id]);

    const pageStyle = {
        backgroundColor: webSettings?.pages?.current_news?.background_color,
        backgroundImage:
            webSettings?.pages?.current_news?.background_image &&
            ` url(${webSettings.pages.current_news.background_image})`,
    };

    return (
        <div className={style["current-news_main"]} style={pageStyle}>
            {isAdmin && <NewEditor />}
            <div className="container">
                <img
                    src="assets/images/arrow-left.png"
                    alt="arrow left"
                    className={style["back-to"]}
                    onClick={() => navigate(-1)}
                />

                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className={style["current-news_wrapper"]}>
                            <img
                                src={currentNews?.image}
                                alt="news"
                                className={style["image"]}
                                // onError={setDefaultImage}
                            />
                            <div className={style["info-wrapper"]}>
                                <div className={style["info-head"]}>
                                    <h1 className={style["title"]}>{currentNews?.title}</h1>
                                    <p className={style["date"]}>
                                        {formatDate(currentNews?.createdAt || "2021-01-01")}
                                    </p>
                                </div>
                                <p className={style["description"]}>{currentNews?.description}</p>
                            </div>
                        </div>

                        <section className={style["related-news"]}>
                            <h2 className={style["related-news_title"]}>
                                You May Be Interested Also
                                {handleText("subtitle")}
                            </h2>

                            <div className={style["related-new_wrapper"]}>
                                {news.map((item) => {
                                    return (
                                        <NewsCard
                                            key={item.id}
                                            news={item}
                                            cardClick={clickCardHandler}
                                        />
                                    );
                                })}
                            </div>
                        </section>
                    </>
                )}
            </div>
        </div>
    );
}
