import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "./store";

import { Helmet } from "react-helmet";

import "antd/dist/antd.css";

import REQUESTS from "./api/requests";

import PATHS from "./config/paths";

import HomePage from "./pages/home/index.js";
import ActivationPage from "./pages/activationPage/ActivationPage.jsx";
import Reseller from "./pages/reseller/Reseller.jsx";
import NewsPage from "./pages/news/NewsPage.js";
import ContactUs from "./pages/contact/ContactUs.jsx";
import LayoutComponent from "./layout";
import CurrentNews from "./pages/news/CurrentNews.jsx";
import LoginByMac from "./pages/login-by-mac/LoginByMac";
import PlaylistsPage from "./pages/playlists/PlaylistsPage";

import PrivacyPolicyPage from "./pages/referring-pages/PrivacyPolicyPage.jsx";
import UploadPlaylistPage from "./pages/upload-playlist/UploadPlaylistPage.jsx";
import MoreInfoAboutPartner from "./pages/referring-pages/MoreInfoAboutPartner.jsx";
import PaymentSuccessPage from "./pages/activationPage/PaymentSuccessPage.jsx";
import PaymentErrorPage from "./pages/activationPage/PaymentErrorPage.jsx";
import Chat from "./pages/real-time-chat/Chat.jsx";

import "./index.scss";
import TermsOfSales from "./pages/referring-pages/TermsOfSales";
import TermsOfService from "./pages/referring-pages/TermsOfService";
import web_settings from "./config/web_settings";
import NotFound from "./pages/notFoundPage/NotFound";

// First we need to get widgets from the server and save them in the Redux store.
// We have a loading screen that will be displayed until the widgets are loaded.
// Our pages in the App.js and we hava check if values of news, reseller, contact us, chat are false, block theses pages.
// And hide theses pages from the menu and from the footer.

// Also from the app.js we need to check if the widgets.editor.enabled is false, block the functionality of the editor.
// In the home page you must be able to check news value and if it is false, hide the news section,
// In the activation page you must be able to check widgets.coupons.enabled and if it is false, hide the coupons section,

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdmin = useSelector((store) => store.globalState.isAdmin);

  const { widgets, appInfo } = useSelector((state) => state.globalState);

  const { setWebSettings } = bindActionCreators(actions, dispatch);
  const { setAdmin } = bindActionCreators(actions, dispatch);
  const { setWidgets } = bindActionCreators(actions, dispatch);

  const [isLoading, setIsLoading] = useState(true);

  const configWebSettings = (data) => {
    console.log(data, data.globals.active_color);
    const root = document.documentElement;
    root.style.setProperty("--active", data.globals.active_color);
    root.style.setProperty("--background-color", data.globals.background_color);
    root.style.setProperty(
      "--button-text-color",
      data.globals.buttons_text_color
    );
    root.style.setProperty("--hover-color", data.globals.hover_color);
    root.style.setProperty("--text-color", data.globals.text_color);
    root.style.setProperty("--title-color", data.globals.title_color);
    root.style.setProperty("--logo-size", `${data.globals.logo_size}px`);
    root.style.setProperty(
      "--navbar-background-color",
      data.navbar.background_color
    );
    root.style.setProperty("--navbar-text-color", data.navbar.text_color);
  };

  const getEditorConfigs = () => {
    setIsLoading(true);
    REQUESTS.GET_EDITOR_CONFIGS(
      (data) => {
        setWebSettings(JSON.parse(data.json_configs));

        configWebSettings(JSON.parse(data.json_configs));

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const getWidgets = () => {
    REQUESTS.GET_WIDGETS(
      (data) => {
        setWidgets(data);
        setIsLoading(false);
      },
      (err) => setIsLoading(true)
    );
  };

  useEffect(() => {
    getWidgets();

    configWebSettings(web_settings);

    getEditorConfigs();

    // if (widgets?.editor?.enabled === "true") {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token != null) {
      sessionStorage.setItem("TOKEN", token);
    }

    if (sessionStorage.getItem("TOKEN")) {
      REQUESTS.VALIDATE_TOKEN(
        {},
        () => {
          setAdmin(true);
        },
        () => {
          setAdmin(false);
          sessionStorage.removeItem("TOKEN");
        },
        token
      );
      // }
    } else {
      setAdmin(false);
    }
  }, []);

  return (
    <div className="App">
      {isLoading && <div className="full-screen-loading"></div>}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={
            appInfo && appInfo.app_name && `${appInfo?.app_name} WEB APP`
          }
        />
        <title>{appInfo?.app_name && `${appInfo?.app_name}  Web`}</title>
        <link
          rel="icon"
          type="image/png"
          href={appInfo?.favicon}
          sizes="16x16"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route path={PATHS.HOME} element={<HomePage />} />
          <Route path="/" element={<Navigate replace to="home" />} />
          <Route path="activation" element={<ActivationPage />} />

          {widgets &&
            widgets.hide_upload_playlist &&
            widgets.hide_upload_playlist.enabled &&
            widgets.hide_upload_playlist.enabled === "false" && (
              <Route
                path={PATHS.UPLOAD_PLAYLIST}
                element={<UploadPlaylistPage />}
              />
            )}

          {widgets?.referral?.enabled === "true" &&
            widgets?.reseller?.enabled === "true" && (
              <Route path="reseller" element={<Reseller />} />
            )}
          {widgets?.news?.enabled === "true" && (
            <>
              <Route path={PATHS.NEWS} element={<NewsPage />} />
              <Route path="news/:id" element={<CurrentNews />} />
            </>
          )}
          {widgets?.support?.enabled === "true" && (
            <Route path={PATHS.CONTACT_US} element={<ContactUs />} />
          )}
          {widgets?.chat?.enabled === "true" && (
            <Route path="chat" element={<Chat />} />
          )}
          {isAdmin && <Route path="chat" element={<Chat />} />}

          {widgets?.user_login?.enabled === "true" && (
            <>
              <Route path={PATHS.LOGIN} element={<LoginByMac />} />
              <Route path={PATHS.PLAYLISTS} element={<PlaylistsPage />} />
            </>
          )}
          <Route path="info-link" element={<MoreInfoAboutPartner />} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
          <Route path="payment-success" element={<PaymentSuccessPage />} />
          <Route path="payment-error" element={<PaymentErrorPage />} />

          <Route path={PATHS.TERMS_OF_USE} element={<TermsOfService />} />
          <Route path={PATHS.TERMS_OF_SALE} element={<TermsOfSales />} />
          <Route path="*" element={<NotFound />} />
        </Route>
   
      </Routes>
    </div>
  );
}

export default App;
