import { Modal } from "antd";

export default function ModalComponent(props) {
    return (
        <Modal
            title={null}
            footer={null}
            centered
            // closable={false}
            width={props.width}
            className="modal-component"
            bodyStyle={{
                // height: "60vh",
                // background: "#FFFFFF",
                padding: "50px 0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
            {...props}
        >
            {props.children}
        </Modal>
    );
}
