import React, { useState } from "react";

import { Form } from "antd";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

import "./_style.scss";

export default function CouponActivation() {
    const translation = useTranslation();

    const [macIsValid, setMacIsValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [form] = Form.useForm();

    const handleText = (text) => {
        if (translation?.pages?.activation?.[text]) {
            return translation?.pages?.activation?.[text];
        }

        return TEXT?.pages?.activation?.[text];
    };

    const onFinish = (values) => {
        function callback(data) {
            form.resetFields();
            setMacIsValid(false);
            setSuccessMessage("success_message");
        }
        function errorCallback() {
            setSuccessMessage(null);
            setErrorMessage("error_message");
        }
        REQUESTS.COUPON_ACTIVATION(values, callback, errorCallback);
    };
    const onFinishFailed = () => {};

    return (
        <>
            <Form
                name="coupon-activation"
                form={form}
                className="activation-form-style"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div style={{ width: "100%", marginTop: 20 }}>
                    <Form.Item
                        name="code"
                        required
                        rules={[
                            {
                                required: true,
                                message: handleText("gift_code_input_message"),
                            },
                        ]}
                    >
                        <InputComponent placeholder={handleText("gift_code_input_placeholder")} />
                    </Form.Item>
                    <ValidateMacAddress reset={successMessage} setMacIsValid={setMacIsValid} />
                    <div className="coupon-activation-activate-btn">
                        <ButtonComponent
                            onClick={() => form.submit()}
                            title={handleText("button_text")}
                            disabled={!macIsValid}
                        />
                    </div>
                </div>
            </Form>
            <SuccessModal
                message={successMessage}
                visible={successMessage ? true : false}
                onClose={() => setSuccessMessage(null)}
            />
            <ErrorModal
                message={errorMessage}
                visible={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
            />
        </>
    );
}
