import style from "../style/card.module.scss";

const Card = ({ bg, title, text, image, reverse, imageStyle, iconSize }) => {
    return (
        <div className={bg ? `${style["card"]} ${style["bg"]}` : style["card"]}>
            <div className={style["container"]}>
                {reverse ? (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <img src={image} style={{ ...imageStyle }} width={`${iconSize}px`} />
                        </div>

                        <div className={style["desc"]}>
                            <h1>{title}</h1>
                            <p>{text}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style["desc"]}>
                            <h1>{title}</h1>

                            <p>{text}</p>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img src={image} style={imageStyle} width={`${iconSize}px`} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Card;
