import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";

import { Menu, Dropdown } from "antd";

import useTranslation from "../../hooks/useTranslation";

import { actions } from "../../store";

import REQUESTS from "../../api/requests";

import NavDrawer from "./NavDrawer";

import Languages from "./Languages";

import TEXT from "../../config/text";
import { ICONS } from "../../config/icons";
import PATHS from "../../config/paths";
import IMAGES from "../../config/images";
import { RESELLER_HOST } from "../../config";

import GlobalEditor from "../../editors/GlobalEditor";

import style from "../style/navbar.module.scss";
import ButtonComponent from "../../components/ButtonComponent";

export default function Navbar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { setAppInfoInRedux } = bindActionCreators(actions, dispatch);

  const { webSettings } = useSelector((store) => store.globalState);

  const translation = useTranslation();

  const { isAdmin } = useSelector((store) => store.globalState);

  const { widgets } = useSelector((state) => state.globalState);

  const [visible, setVisible] = useState(false);

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };

  const logout = () => {
    const TOKEN = localStorage.getItem("TOKEN");

    if (TOKEN) {
      localStorage.clear(TOKEN);
      navigate("/login");
    }
  };

  const getAppInfo = () => {
    function callback(data) {
      setAppInfoInRedux(data);
    }

    REQUESTS.APP_INFO(callback);
  };

  const menu = (
    <Menu className={style["reseller-dropdown"]}>
      <Menu.Item>
        <NavLink
          to={PATHS.RESELLER}
          rel="noreferrer"
          className={({ isActive }) =>
            isActive
              ? `${style["dropdown-item"]} ${style["active-link"]}`
              : style["dropdown-item"]
          }
        >
          {handleText("register", "page_name")}
        </NavLink>
      </Menu.Item>

      <Menu.Item>
        <a
          href={RESELLER_HOST + "#/login"}
          target="_blank"
          rel="noreferrer"
          className={style["dropdown-item"]}
        >
          {translation?.general?.login || TEXT?.general?.login}
        </a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    getAppInfo();
  }, []);

  return (
    <>
      <div className={style["menu-icon"]} onClick={() => setVisible(true)}>
        <MenuOutlined color="white" />
      </div>
      <div className={style.container}>
        <NavLink to={PATHS.HOME}>
          <img
            src={webSettings?.globals.logo || IMAGES.LOGO}
            alt="brand-logo"
            loading="lazy"
            className={style.logo}
            style={{ opacity: webSettings?.globals.logo ? 1 : 0 }}
          />
        </NavLink>
        <div className={style.routes}>
          <NavLink
            to={PATHS.HOME}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
          >
            {handleText("home", "page_name")}
          </NavLink>
          <NavLink
            to={PATHS.ACTIVATION}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
          >
            {handleText("activation", "page_name")}
          </NavLink>
          
          {widgets &&
            widgets.hide_upload_playlist &&
            widgets.hide_upload_playlist.enabled &&
            widgets.hide_upload_playlist.enabled === "false" && (
              <NavLink
                to={PATHS.UPLOAD_PLAYLIST}
                className={({ isActive }) =>
                  isActive
                    ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                    : style["menu-list__item__link"]
                }
              >
                {handleText("upload_playlist", "page_name")}
              </NavLink>
            )}

          {widgets?.referral?.enabled === "true" &&
            widgets?.reseller?.enabled === "true" && (
              <Dropdown overlay={menu}>
                <div className={style["menu-list__item__link"]}>
                  {handleText("reseller", "page_name")}
                </div>
              </Dropdown>
            )}
          {widgets?.news?.enabled === "true" && (
            <NavLink
              to={PATHS.NEWS}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("news", "page_name")}
            </NavLink>
          )}
          {widgets?.support?.enabled === "true" && (
            <NavLink
              to={PATHS.CONTACT_US}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("contact_us", "page_name")}
            </NavLink>
          )}
          {isAdmin && (
            <NavLink
              to={PATHS.CHAT}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("chat", "page_name")}
            </NavLink>
          )}
        </div>
      </div>

      <div className={style["navbar-login-lang-section"]}>
        {widgets?.user_login?.enabled === "true" &&
          (window.location.hash === "#/playlists" ? (
            <div onClick={logout} className={style["log-button"]}>
              <NavLink to={PATHS.PLAYLISTS} className={style["logout"]}>
                <ButtonComponent
                  title={handleText("playlist", "logout_button_text")}
                />
              </NavLink>
              <NavLink to={PATHS.PLAYLISTS} className={style["logout-icon"]}>
                {ICONS.LOGOUT}
              </NavLink>
            </div>
          ) : (
            <div className={style["log-button"]}>
              <NavLink to={PATHS.LOGIN} className={style["login"]}>
                <ButtonComponent title={handleText("login", "page_name")} />
              </NavLink>
              <NavLink to={PATHS.LOGIN} className={style["login-icon"]}>
                {ICONS.LOGIN}{" "}
              </NavLink>
            </div>
          ))}

        <Languages />
      </div>
      {isAdmin && <GlobalEditor />}

      <NavDrawer visible={visible} onClose={() => setVisible(false)} />
    </>
  );
}
