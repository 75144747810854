import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import socketIOClient from "socket.io-client";

import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import { HOST } from "../../config";
import REQUESTS from "../../api/requests";
import { ICONS } from "../../config/icons";
import IMAGES from "../../config/images";
import Messages from "./Messages";

import "./_chat.scss";
import ChatEditor from "../../editors/ChatEditor";

export let socket = null;
// const HOST = "http://192.168.8.123:10034/";

export default function Chat() {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);

  const appInfo = useSelector((state) => state.globalState.appInfo);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ref = useRef();

  const isAdmin = useSelector((store) => store.globalState.isAdmin);

  const translation = useTranslation();
  const webSettings = useSelector((state) => state.globalState.webSettings);

  const pageStyle = {
    backgroundImage: `url(${webSettings?.pages?.chat?.background_image})`,
    backgroundColor: webSettings?.pages?.chat?.background_color,
  };

  const handleText = (text) => {
    if (translation?.pages?.chat?.[text]) {
      return translation?.pages?.chat?.[text];
    }

    return TEXT?.pages?.chat?.[text];
  };

  const connect = () => {
    const token = sessionStorage.getItem("TOKEN");

    if (!isConnected) {
      socket = socketIOClient(HOST, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      socket.on("connect", () => {
        setIsConnected(true);
      });

      socket.on("connect_error", () => {
        setIsConnected(false);
      });

      socket.on("message", (data) => {
        getMessages();
      });
    }
  };

  const chatLogin = () => {
    const mac = searchParams.get("mac");
    const code = searchParams.get("code");

    const body = {
      mac,
      code,
    };

    function callback(token) {
      sessionStorage.setItem("TOKEN", token);
    }

    function errorCallback(err) {
      // navigate("/home");
    }

    REQUESTS.CHAT_LOGIN(callback, errorCallback, body);
  };

  const sendMessage = (e) => {
    e.preventDefault();

    function callback() {
      setText("");
      setMessages([
        ...messages,
        {
          message: text,
          sender: "user",
        },
      ]);
      getMessages();
      console.log(messages);
    }

    function errorCallback() {}

    if (text?.trim()) {
      REQUESTS.MESSAGES.SEND(callback, errorCallback, { message: text });
    }
  };

  const getMessages = () => {
    function callback(data) {
      const rows = data.rows.reverse();
      setMessages(rows);
    }

    function errorCallback() {}
    REQUESTS.MESSAGES.GET(callback, errorCallback);
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage(e);
    }
  };

  useEffect(() => {
    const mac = searchParams.get("mac");
    const code = searchParams.get("code");

    if (isAdmin) {
      chatLogin();
    } else if (mac && code) {
      chatLogin();
    } else {
      navigate("/home");
    }
  }, [searchParams.get("mac"), searchParams.get("code")]);

  useEffect(() => {
    let interval = null;

    if (!isConnected) {
      interval = setTimeout(() => {
        connect();
      }, 5000);
    } else {
      getMessages();
    }
    return () => clearTimeout(interval);
  }, [isConnected]);

  useEffect(() => {
    connect();
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("message");
        socket.io.disconnect();
      }
    };
  }, []);

  return (
    <div className="chat-wrapper" style={pageStyle}>
      {isAdmin && <ChatEditor />}
      <div className="chat-container">
        <div
          className="title"
          style={{
            background:
              webSettings &&
              webSettings.pages &&
              webSettings.pages.chat &&
              webSettings.pages.chat.header_background_color,
          }}
        >
          {isConnected ? handleText("title_two") : handleText("title_one")}
        </div>
        {messages.length > 0 ? (
          <Messages messages={messages} />
        ) : (
          <div className="start-content">
            <span className="text">{handleText("chat_start_text")}</span>
            {appInfo && appInfo.logo && (
              <div className="chat-nomessage-logo-content">
                <img
                  src={appInfo.logo}
                  alt=""
                  className="chat-nomessage-logo-content__img"
                />
              </div>
            )}
          </div>
        )}
        <div className="message-content">
          <form onSubmit={sendMessage} ref={ref}>
            <textarea
              className="message"
              placeholder={handleText("message_input_placeholder")}
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={onEnterPress}
            ></textarea>
            <button className="sent-btn" disabled={!isConnected}>
              {ICONS.CHAT_SENT_BUTTON}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
